<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="渠道名称" prop="name">
        <el-input v-model.trim="form.name" />
      </el-form-item>

      <el-form-item label="邀请码代码" prop="invitationCode" v-if="identityDisabled">
        <el-input v-model="form.invitationCode" :disabled="identityDisabled" />
      </el-form-item>

      <el-form-item label="邀请身份" prop="identity">
        <el-select v-model="form.identity" placeholder="选择邀请身份" @change="identityChange" :disabled="identityDisabled">
          <el-option v-for="(item, index) in identityList" :key="index" :label="item.name" :value="item.identity" />
        </el-select>
      </el-form-item>
      <el-form-item label="使用场景" prop="useScene">
        <el-select v-model="form.useScene" placeholder="选择使用场景" :disabled="useSceneDisabled">
          <el-option v-for="(item, index) in userSence" :key="index" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="可报名人数" prop="defineUseNum" v-if="!identityDisabled">
        <el-input-number v-model="form.defineUseNum" :min="0" :max="100000000" :step="1" :step-strictly="true" />
      </el-form-item>

      <!-- <el-form-item label="appKey" prop="appKey">
        <el-select v-model="form.appKey">
          <el-option :label="item.name" :value="item.appKey" v-for="(item,index) in wcs" :key="index"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  reactive,
  toRefs,
  onMounted,
} from 'vue'
import { channelInvitationAddOrUpdate, doAdd } from '@/api/channel'
import { useStore } from 'vuex'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
    meetingType: {
      type: String,
      default: '',
    },
    identityList: {
      type: Array,
      default: [],
    },
    meetingCode: {
      type: String,
      default: '',
    },
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证

    const state = reactive({
      formRef: null,
      form: {
        useScene: '',
        name: '',
        identity: '',
        defineUseNum: 0,
      },
      departmentData: [],
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入渠道名称' }],
        remarks: [
          { required: true, trigger: 'blur', message: '请输入渠道备注' },
        ],
        identity: [
          { required: true, trigger: 'change', message: '请选择邀请身份' },
        ],
        useScene: [
          { required: true, trigger: 'change', message: '请选择使用场景' },
        ],
        defineUseNum: [
          { required: true, trigger: 'blur', message: '请输入可使用次数' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      userSence: [
        {
          value: 'FORM',
          label: '注册表单',
        },
        {
          value: 'TICKET',
          label: '购票',
        },
      ],
      useSceneDisabled: false,
      identityDisabled: false,
    })

    const showEdit = async (row) => {
      console.log(row)
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'
        state.useSceneDisabled = true
        state.identityDisabled = true
        row.name = row.channelName
        state.form = Object.assign({}, row)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        useScene: '',
        name: '',
        identity: '',
        defineUseNum: 0,
      }
      state.useSceneDisabled = false
      state.identityDisabled = false
      state.dialogFormVisible = false
    }
    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          state.form.meetingCode = props.meetingCode
          state.form.meetingType = props.meetingType
          await channelInvitationAddOrUpdate(state.form)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    const identityChange = (e) => {
      console.log(e)
      if (e == 'AUDIENCE') {
        state.form.useScene = ''
        state.useSceneDisabled = false
      } else {
        state.form.useScene = 'FORM'
        state.useSceneDisabled = true
      }
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      identityChange,
    }
  },
})
</script>
